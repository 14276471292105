<template>
  <CRow class="overflow-x-auto">
    <div class="text-right col-12 mb-3"></div>
    <div class="table-responsive">
      <table class="table table-dark">
        <thead>
          <tr>
            <th scope="col">id</th>
            <th scope="col">Property Id</th>
            <th scope="col">Property Title</th>
            <th scope="col">Start Date</th>
            <th scope="col">Contract Length</th>
            <th scope="col">Layout</th>
            <th scope="col">Contract</th>
            <th scope="col">User</th>
            <th scope="col">Capacity</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item['id']">
            <td>{{ getContractId(index + 1) }}</td>
            <td>{{ item["property_id"] }}</td>
            <td>
              {{
                item["property_details"] && item["property_details"]["title"]
                  ? item["property_details"]["title"]
                  : "N/A"
              }}
            </td>
            <td>{{ item["start_date"] | getFormatDate }}</td>
            <td>{{ item["contract_length"] | getLength }}</td>
            <td>{{ item["layout"] }}</td>
            <td>
              <a
                v-if="item.terms_and_condition_file"
                :href="`${baseAPI}${item.terms_and_condition_file}`"
                download="download"
                target="_blank"
                >View</a
              >
              <a v-else>No File</a>
            </td>
            <td>
              <ul>
                <li>
                  Name :
                  {{
                    item["user_details"] && item["user_details"]["name"]
                      ? item["user_details"]["name"]
                      : "N/A"
                  }}
                </li>
                <li>
                  Email :
                  {{
                    item["user_details"] && item["user_details"]["email"]
                      ? item["user_details"]["email"]
                      : "N/A"
                  }}
                </li>
                <li>
                  Mobile :
                  {{
                    item["user_details"] && item["user_details"]["mobile"]
                      ? item["user_details"]["mobile"]
                      : "N/A"
                  }}
                </li>
              </ul>
            </td>
            <td>{{ item["capacity"] }}</td>
            <td>
              <CButton
                class="right-create"
                size="sm"
                color="primary"
                @click="onClickEdit(item['id'])"
                >Edit</CButton
              >
              &nbsp;
              <CButton
                class="right-create"
                size="sm"
                color="danger"
                @click="deleteContract(item['id'])"
                >Delete
              </CButton>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-end w-100 mb-3">
      <nav aria-label="Page navigation example" class="mr-3">
        <ul class="pagination mb-0">
          <li class="page-item">
            <a
              class="page-link"
              @click="onLoad('prev')"
              aria-label="Previous"
              id="prev"
            >
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li
            class="page-item"
            :class="{ active: t === current_page }"
            v-for="t in totalPage"
            :key="t"
          >
            <a class="page-link" @click="onPageLoad(t)">{{ t }}</a>
          </li>
          <li class="page-item">
            <a
              class="page-link"
              @click="onLoad('next')"
              aria-label="Next"
              id="next"
            >
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
      <select v-model="selected" @change="onSelect" class="px-3">
        <option :selected="true">5</option>
        <option>10</option>
        <option>50</option>
      </select>
    </div>
    <contractEditForm
      v-if="modalShow"
      :modalShow="modalShow"
      :id="contract_id"
      @saveContract="saveContract"
      :on-hide-modal="onHideModal"
    ></contractEditForm>
  </CRow>
</template>

<script>
import { getContracts, deleteContract } from "@/api/contracts";
import contractEditForm from "./contractEditForm.vue";

export default {
  name: "Contracts",
  components: {
    contractEditForm,
  },
  data: () => {
    return {
      items: [],
      fields: [
        { key: "start_date" },
        { key: "contract_length" },
        { key: "layout" },
        { key: "capacity" },
      ],
      baseAPI: process.env.VUE_APP_BASE_API,
      modalShow: false,
      contract_id: 0,
      perPage: 5,
      prev: "",
      next: "",
      last: "",
      totalData: "",
      totalPage: "",
      current_page: 1,
      selected: 5,
    };
  },
  created() {
    getContracts(1, this.perPage)
      .then((res) => {
        this.items = (res.data && res.data.data.data) || [];
        this.prev = res.data.data.prev_page_url;
        this.next = res.data.data.next_page_url;
        if (this.prev === null) {
          document.getElementById("prev").classList.add("disable_a_href");
        }
        this.last = res.data.data.last_page_url;
        if (this.next === null) {
          document.getElementById("next").classList.add("disable_a_href");
        }
        this.perPage = res.data.data.per_page;
        this.totalData = res.data.data.total;
        this.totalPage = Math.ceil(this.totalData / this.perPage);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  },
  paginationProps: {
    align: "center",
    doubleArrows: false,
    previousButtonHtml: "prev",
    nextButtonHtml: "next",
  },
  filters: {
    getLength(data) {
      if (data == 12) {
        return "1 year";
      }
      if (data > 11) {
        return (
          Math.floor(data / 12) +
          " years " +
          (data % 12 > 0 ? (data % 12) + " months" : "")
        );
      } else return data + " months";
    },
    getFormatDate(data) {
      let startDate = data.split("-");
      return startDate[2] + "/" + startDate[1] + "/" + startDate[0];
    },
  },
  methods: {
    getContractId(data) {
      if (this) return (this.current_page - 1) * parseInt(this.perPage) + data;
      return null;
    },
    onSelect(event) {
      let value = event.target.value;
      getContracts(1, value)
        .then((res) => {
          this.items = (res.data && res.data.data.data) || [];
          this.isLoading = false;
          this.perPage = res.data.data.per_page;
          this.prev = res.data.data.prev_page_url;
          if (this.prev === null) {
            document.getElementById("prev").classList.add("disable_a_href");
          }
          this.last = res.data.data.last_page_url;
          if (this.next === null) {
            document.getElementById("next").classList.add("disable_a_href");
          }
          this.next = res.data.data.next_page_url;
          this.totalData = res.data.data.total;
          this.totalPage = Math.ceil(this.totalData / this.perPage);
        })
        .catch((error) => {
          // reject(error)
          console.log(error, "error");
        });
    },
    onLoad(value) {
      if (value === "prev") {
        if (this.current_page != 1)
        this.current_page = this.current_page - 1;
      } else if (this.current_page < this.totalPage) {
        this.current_page = this.current_page + 1;
      }
      this.onPageLoad(this.current_page);
    },
    onPageLoad(page) {
      getContracts(page, this.perPage)
        .then((res) => {
          this.items = (res.data && res.data.data.data) || [];
          this.perPage = res.data.data.per_page;
          this.prev = res.data.data.prev_page_url;
          this.last = res.data.data.next_page_url;
          if (this.prev !== null) {
            document.getElementById("prev").classList.remove("disable_a_href");
          } else {
            document.getElementById("prev").classList.add("disable_a_href");
          }
          if (this.next !== null) {
            document.getElementById("next").classList.remove("disable_a_href");
          } else {
            document.getElementById("next").classList.add("disable_a_href");
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
      this.current_page = page;
    },

    onClickEdit(id) {
      this.modalShow = true;
      this.contract_id = id;
    },
    onHideModal() {
      this.modalShow = !this.modalShow;
      console.log("hide ");
      this.contract_id = 0;
    },
    saveContract(data, id = null) {
      if (id) {
        var foundIndex = this.items.findIndex((x) => x.id == id);
        this.items[foundIndex] = data;
      } else {
        this.items = data.data;
        this.perPage = data.per_page;
        this.totalData = data.total;
        this.totalPage = Math.ceil(this.totalData / this.perPage);
      }
    },
    deleteContract(id) {
      if (confirm("Do you really want to delete contract?")) {
        deleteContract(id)
          .then((res) => {
            console.log(res.data.data, "=====");
            this.items = (res.data && res.data.data.data) || [];
            this.$notify({
              group: "notify",
              type: "success",
              text: res.data.message,
            });
            this.perPage = res.data.data.per_page;
            this.totalData = res.data.data.total;
            this.totalPage = Math.ceil(this.totalData / this.perPage);
          })
          .catch((error) => {
            if (error.response.status == 500) {
              this.$notify({
                group: "notify",
                type: "error",
                text: error.response.data.data,
              });
            }
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.disable_a_href {
  pointer-events: none;
}

.active {
  background-color: blue;
}

li {
  cursor: pointer;
}
</style>
